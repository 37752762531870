import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Widgets from '../components/widgets/Widgets' 
import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function Page({ data }) {
  const page = data.allWpPage.nodes[0];
  return (
    <Layout className={`page ${page.slug}`}>
      <Seo post={page} />
	  <article>
		<Widgets widgets={page.widgets?.pageWidgets} page={page.slug} />	
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
		slug
		...WidgetFragment
        ...PageSEOFragment
      }
    }
  }
`